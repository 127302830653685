import React from 'react'

import Window from '../utils/window'

/**
 * This page just redirects the user to the Resume subsection of the Portfolio page
 */
export default function Resume() {
  React.useEffect(() => {
    Window.navigate('../resumes')
  }, [])

  return null
}
